export const FeatureFlagName = {
  OutageMessage: 'odd-outage-message',
  PreOutageMessage: 'odd-pre-outage-message',
  CustomOutageMessage: 'odd-custom-outage-message',
  PreferredContactMethod: 'odd-preferred-contact-method',
  GetConnectedPage: 'odd-get-connected',
  GetAccreditedEnhancements: 'odd-get-accredited',
  AccreditedBusinessSeal: 'odd-ab-seal',
  GetAccreditedLinkText: 'odd-get-accredited-link-text',
} as const;
