import { useEffect, useId, useState, useMemo, useRef } from 'react';

import ContactForm from './ContactForm';
import { DialogContactForm, DialogContactFormHeading } from './styles';
import { useContactDialog } from './context';

function DialogContactFormComponent() {
  const headingId = useId();
  const headingRef = useRef<HTMLDivElement>(null);

  const dialog = useContactDialog();

  const { toEmail, entityType, entityId, headingText, thankYouText } = dialog.state.props ?? {};

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const _heading = useMemo(
    () => (entityType ? `Email this ${entityType}` : headingText ?? 'Email this BBB'),
    [entityType, headingText],
  );

  useEffect(() => {
    headingRef.current?.focus();
  }, [isSubmitted]);

  return (
    <DialogContactForm
      autoFocusDialogElement={false}
      isOpen={dialog.state.open}
      close={() => {
        dialog.dispatch({ type: 'close' });
      }}
      $submitted={isSubmitted}
    >
      {toEmail && (
        <div className="stack">
          <DialogContactFormHeading
            $submitted={isSubmitted}
            className="text-size-50"
            component="h2"
            id={headingId}
            ref={headingRef}
            tabIndex={-1}
            variant="h3"
          >
            {!isSubmitted ? _heading : thankYouText ?? 'Your message was successfully sent to this BBB'}
          </DialogContactFormHeading>
          {!isSubmitted && (
            <ContactForm additionalValues={{ toEmail, entityType, entityId }} setIsSubmitted={setIsSubmitted} />
          )}
        </div>
      )}
    </DialogContactForm>
  );
}

export default DialogContactFormComponent;
