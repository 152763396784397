import Dialog from '@iabbb/shared/components/UI/Dialog';
import styled from '@emotion/styled';
import Typography from '@iabbb/bds-react/Typography';

type DialogContactFormProps = {
  $submitted: boolean;
};

export const DialogContactForm = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== '$submitted',
})<DialogContactFormProps>`
  transition: background ease-in-out 200ms;

  &[open] {
    ${(props) =>
      props.$submitted &&
      `
      background: var(--bds-color-blue-medium-87);
    `}

    h2 {
      text-align: center;
    }

    .dialog-close {
      transition: color ease-in-out 200ms;

      ${(props) =>
        props.$submitted &&
        `
    color: var(--bds-color-gray-10);
  `}
    }
  }
`;

export const DialogContactFormHeading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== '$submitted',
})<DialogContactFormProps>`
  transition:
    padding ease-in-out 200ms,
    color ease-in-out 200ms;
  ${(props) =>
    props.$submitted &&
    `
    padding: 30px 0;
    color: var(--bds-color-white);
  `}
`;
