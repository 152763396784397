import styled from '@emotion/styled';

const Dialog = styled.dialog`
  &[open] {
    background: var(--bds-color-gray-10);
    border: none;
    box-shadow: var(--bds-shadow-base);
    inline-size: var(--dialog-max-width, 31.25rem);
    overflow-y: auto;
    padding: 1.5rem;
    padding-block-start: 2.25rem;

    &::backdrop {
      background: hsla(0, 100%, 0%, 0.75);
      backdrop-filter: blur(2px);
    }

    &:focus {
      outline: 3px solid var(--bds-color-blue-light);
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:focus-visible {
      outline: 3px solid var(--bds-color-blue-light);
    }

    &:not(:has(.dialog-close)) {
      padding-block-start: 1.5rem;
    }

    .dialog-close {
      color: inherit;
      padding: 0.25rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      svg {
        display: block;
      }
    }
  }
`;

export default Dialog;
