import { IconProps } from '@iabbb/icons';
import IconSpinner from '@iabbb/icons/Spinner';
import print from '@iabbb/shared/styles/print';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import clsx from 'clsx';

interface LoadingSpinnerProps {
  className?: string;
  props?: IconProps;
  style?: React.CSSProperties;
}

const spinKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const AnimatedIconSpinner = styled(IconSpinner)`
  animation: ${spinKeyframes} 750ms infinite steps(8);
  font-size: var(--spinner-size, 1rem);
`;

export default function LoadingSpinner({ className, ...props }: LoadingSpinnerProps) {
  return (
    <div
      className={clsx(className, print.nothing)}
      /*
       ** https://www.w3.org/TR/wai-aria-1.2/#progressbar
       ** biome-ignore lint/a11y/useAriaPropsForRole: props that are being complained about are not required, according to spec
       */
      role="progressbar"
      aria-valuetext="in progress, please wait..."
    >
      <AnimatedIconSpinner {...props} />
      <span className="visually-hidden">loading...</span>
    </div>
  );
}
